<template>
  <svg id="Regular" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M17.25,15a.75.75,0,0,0-.75-.75H14.25V12a.75.75,0,0,0-.75-.75h-3a.75.75,0,0,0-.75.75v2.25H7.5a.75.75,0,0,0-.75.75v3a.75.75,0,0,0,.75.75H9.75V21a.75.75,0,0,0,.75.75h3a.75.75,0,0,0,.75-.75V18.75H16.5a.75.75,0,0,0,.75-.75Z"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="20.25"
      y1="0.75"
      x2="20.25"
      y2="23.25"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M23.25.75V7.5a3.009,3.009,0,0,1-3,3h0a3.009,3.009,0,0,1-3-3V.75"
    />
    <line
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      x1="3.75"
      y1="10.5"
      x2="3.75"
      y2="23.25"
    />
    <path
      fill="none"
      stroke="currentColor"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="1.5"
      d="M3.75,10.5h0a3.009,3.009,0,0,1-3-3V3.75a3.009,3.009,0,0,1,3-3h0a3.009,3.009,0,0,1,3,3V7.5A3.009,3.009,0,0,1,3.75,10.5Z"
    />
  </svg>
</template>
